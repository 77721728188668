<template>
   <div id="application-form-wrapper">
      <div id="application-form-inner-container">
         <div id="close-application-form" @click="handlerCloseApplicationForm">X</div>
         <div class="application-form-overflow">
            <span class="form-title" v-if="!isSuccess">{{ $t("agentApplication.title") }}</span>

            <div id="application-steps" :class="'step-' + activeStep" v-if="!isSuccess">
               <span class="step-name" :class="activeStep >= 1 ? 'active' : ''">1</span>
               <span class="step-name" :class="activeStep >= 2 ? 'active' : ''">2</span>
               <span class="step-name" :class="activeStep == 3 ? 'success' : ''">3</span>
            </div>

            <!-- Contact Informations -->

            <section id="contact-informations" v-if="activeStep == 1">
               <div class="form-section">
                  <div class="input-container">
                     <label for="">{{ $t("agentApplication.name") }}</label>
                     <input type="text" :placeholder="$t('agentApplication.name')" v-model="form.name" />
                  </div>
                  <div class="input-container">
                     <label for="">{{ $t("agentApplication.surname") }}</label>
                     <input type="text" :placeholder="$t('agentApplication.surname')" v-model="form.surname" />
                  </div>
                  <div class="input-container">
                     <label for="">{{ $t("agentApplication.phone") }}</label>
                     <input type="phone" placeholder="+49" v-model="form.phone" />
                  </div>
                  <div class="input-container">
                     <label for="">{{ $t("agentApplication.mail") }}</label>
                     <input type="mail" :placeholder="$t('agentApplication.mail')" v-model="form.mail" />
                  </div>
               </div>
               <label for="">{{ $t("agentApplication.language") }}</label>
               <div class="language-selection-info-box" v-if="form.language.length == 1">
                  {{ $t("agentApplication.selectLang") }}
               </div>
               <ul id="languge-wrapper">
                  <li v-for="(language, index) in languages" :key="index">
                     <input type="checkbox" :id="language.code" v-model="language.selected" :disabled="!language.selected && form.language.length == 2" />
                     <img :src="`https://cdn.prod.reisetech.io/flags/4x3/${language.code}.svg`" alt="" />
                     <label :for="language.code">{{ $t(`agentApplication.language_${language.code}`) }}</label>
                  </li>
               </ul>
            </section>

            <!-- Company Informations -->
            <section id="company-informations" v-if="activeStep == 2">
               <div class="form-section">
                  <div class="input-container">
                     <label for="">{{ $t("agentApplication.companyName") }}</label>
                     <input type="text" placeholder="" v-model="form.companyName" />
                  </div>
                  <div class="input-container">
                     <label for="">{{ $t("agentApplication.companyOfficer") }}</label>
                     <input type="text" placeholder="" v-model="form.authorizedPerson" />
                  </div>
                  <div class="input-container">
                     <label for="">{{ $t("agentApplication.taxNumber") }}</label>
                     <input type="text" placeholder="" v-model="form.taxNumber" />
                  </div>
                  <div class="input-container">
                     <label for="">{{ $t("agentApplication.companyPhone") }}</label>
                     <input type="text" placeholder="" v-model="form.companyPhone" />
                  </div>
                  <div class="input-container">
                     <label for="">{{ $t("agentApplication.ownerEmail") }}</label>
                     <input type="text" placeholder="" v-model="form.adminEmail" />
                  </div>
                  <div class="input-container">
                     <label for="">{{ $t("agentApplication.companyWebPage") }}</label>
                     <input type="text" placeholder="" v-model="form.companyWeb" />
                  </div>
               </div>
               <div class="notice-wrapper">
                  <h6>{{ $t("agentApplication.terms.title") }}</h6>
                  <p>
                     {{ $t("agentApplication.terms.content1") }}
                  </p>
                  <p>
                     {{ $t("agentApplication.terms.content2") }}
                  </p>
               </div>
               <div class="checkbox-wrapper">
                  <!-- <input type="checkbox" v-model="confirm" /> -->
                  <span class="checkbox" @click="handlerConfirmation" :class="confirm ? 'confirmed' : ''"></span>
                  <p>
                     {{ $t("agentApplication.terms.confirmText") }}
                  </p>
               </div>
            </section>

            <!-- Response Informations -->
            <section id="response" v-if="activeStep == 3">
               <p>{{ $t("agentApplication.terms.success1") }}</p>
               <p>{{ $t("agentApplication.terms.success2") }}</p>
            </section>

            <div id="application-form-actions" v-if="activeStep != 3">
               <button @click="handlerPrevStep" class="gts-secondary" :class="activeStep == 2 ? '' : 'invisible'">
                  {{ $t("agentApplication.prevStep") }}
               </button>

               <button @click="handlerNextStep" class="gts-primary" :class="form.language.length > 1 ? '' : 'not-allow'" v-if="activeStep == 1">{{ $t("agentApplication.nextStep") }}</button>

               <button @click="handlerSubmitApplicationForm" class="gts-primary" id="submit-application" v-if="activeStep == 2" :disabled="!confirm">
                  <span v-if="!isLoading">{{ $t("agentApplication.submit") }}</span>
                  <div class="loader" v-if="isLoading"></div>
               </button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { applicationForm } from "@/service/rest.api";
export default {
   name: "ApplicationForm",
   data() {
      return {
         activeStep: 1,
         confirm: false,
         isLoading: false,
         isSuccess: false,
         languages: {
            de: {
               code: "de",
               selected: false,
            },
            ru: {
               code: "ru",
               selected: false,
            },
            tr: {
               code: "tr",
               selected: false,
            },
            en: {
               code: "en",
               selected: false,
            },
         },
         form: {
            name: "",
            surname: "",
            phone: "",
            mail: "",
            language: "",
            companyName: "",
            authorizedPerson: "",
            taxNumber: "",
            companyPhone: "",
            adminEmail: "",
            companyWeb: "",
         },
      };
   },

   watch: {
      languages: {
         handler: function () {
            this.handlerSelectLanguage();
         },
         deep: true,
      },
   },
   methods: {
      handlerSelectLanguage() {
         this.form.language = Object.keys(this.languages)
            .map((key) => this.languages[key])
            .filter((lang) => lang.selected)
            .map((lang) => this.$i18n.t(`agentApplication.language_${lang.code}`));
      },
      handlerConfirmation() {
         const self = this;
         self.confirm = !self.confirm;
      },
      handlerSubmitApplicationForm() {
         const self = this;

         let query = {
            "your-name": self.form.name,
            "your-surname": self.form.surname,
            "your-phone": self.form.phone,
            "your-email": self.form.mail,
            "your-language": self.form.language.join(", "),
            "company-name": self.form.companyName,
            "auth-person": self.form.authorizedPerson,
            "tax-number": self.form.taxNumber,
            "company-phone": self.form.companyPhone,
            "admin-email": self.form.adminEmail,
            "company-web": self.form.companyWeb,
         };

         const form = new FormData();
         for (const field in query) {
            form.append(field, query[field]);
         }

         self.isLoading = true;
         applicationForm(form).then((res) => {
            if (res.status == "mail_sent") {
               self.activeStep = 3;
            }
            self.isLoading = false;
            self.isSuccess = true;
         });
      },
      handlerPrevStep() {
         const self = this;
         if (self.activeStep != 1) {
            self.activeStep--;
         }
      },
      handlerNextStep() {
         const self = this;
         if (self.activeStep != 3 && this.form.language.length > 0) {
            self.activeStep++;
         }
      },
      handlerCloseApplicationForm() {
         const self = this;
         self.$store.state.applicationForm = false;
      },
   },
};
</script>

<style lang="scss" scoped>
.language-selection-info-box {
   background: #fff8d1;
   padding: 0.5rem 1rem;
   border-radius: 0.25rem;
   margin: 0.25rem 0;
   color: #575757;
}
#languge-wrapper {
   display: flex;
   margin-top: 0.5rem;
   background-color: #f5f5f5;
   padding: 1rem;
   border-radius: 8px;
   width: 100%;
   justify-content: space-between;

   @media (max-width: 576px) {
      flex-direction: column;
   }

   li {
      display: flex;
      align-items: center;
      cursor: pointer;

      margin-right: 1rem;
      @media (max-width: 576px) {
         margin: 0.75rem 0;
      }
      input {
         transform: scale(1.5);
         margin-right: 0.5rem;
      }

      img {
         width: 22px;
      }

      label {
         margin-left: 0.25rem !important;
         padding: 0;
         margin-bottom: 0 !important;
         // margin-left: 0.25rem;
         // font-weight: 400;
         // font-size: 1.125rem !important;
      }
   }
   /* li {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #eee;
      margin: 0 0.5rem;
      cursor: pointer;
      transition: all 0.05s ease-in-out;

      &.active {
         outline: 5px solid #33b8fb;
         // outline: 8px solid #363636;
      }
      img {
         width: 100%;
         height: 100%;
         transform: scale(1.5);
      }
      &:hover {
         // transform: scale(1.125);
      }
   }*/
}
#application-form-wrapper {
   position: fixed;
   z-index: 99999;
   background: #00000050;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   #close-application-form {
      position: absolute;
      right: 1rem;
      top: 1rem;
      background: #eaeaea;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #4a4a4a;
      font-weight: 500;
      border-radius: 50%;
      cursor: pointer;
      border: 1px solid #b2b2b2;
   }
   #application-form-inner-container {
      width: 92vw;
      height: fit-content;
      max-height: 90vh;
      position: relative;
      overflow: hidden;
      overflow-y: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      position: absolute;
      background-color: #fff;
      border: 1px solid #ccc;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      border-radius: 0.5rem;
      padding: 3rem 2rem;
      transition-property: height;
      transition-duration: 0.15s;
      transition-timing-function: ease-in-out;

      @media (min-width: 1200px) {
         width: 70vw;
      }
      @media (min-width: 1600px) {
         width: 40vw;
      }

      .application-form-overflow {
         // overflow: hidden;
         // height: 100%;
         width: 100%;

         .form-title {
            font-size: 1.75rem;
            font-weight: 500;
            color: #363636;
         }
         section {
            .form-section {
               display: grid;
               width: 100%;
               grid-template-columns: auto auto;
               column-gap: 1rem;

               @media (max-width: 576px) {
                  grid-template-columns: auto;
               }

               .input-container {
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 1rem;

                  label {
                     font-size: 0.85rem;
                     margin-bottom: 0.25rem;
                  }

                  input {
                     background-color: #eee;
                     border: 0;
                     outline: 0;
                     height: 48px;
                     font-size: 1.125rem;
                     text-indent: 0.5rem;
                     border-radius: 0.5rem;
                     border: 1px solid transparent;

                     &:focus {
                        border: 1px solid #33b8fb;
                     }
                  }
               }
            }

            .notice-wrapper {
               width: auto;
               height: 150px;
               overflow: hidden;
               overflow-y: auto;
               // background-color: #f4f4f4;
               padding: 1rem;
               opacity: 0.5;
               border-radius: 0.5rem;
               h6 {
                  font-size: 1.25rem;
                  padding: 0;
                  margin: 0;
                  font-weight: 500;
               }
               p {
                  font-size: 1rem;
                  line-height: 1.5rem;
                  color: #888;
               }
            }
            .checkbox-wrapper {
               display: flex;
               margin: 2rem 0;
               border-radius: 0.5rem;
               padding: 0.25rem 1rem;
               align-items: center;
               justify-content: center;

               .checkbox {
                  width: 20px;
                  height: 20px;
                  //   background-color: #32b8fb;
                  border: 0.125rem solid #32b8fb;
                  flex-shrink: 0;
                  border-radius: 0.25rem;
                  cursor: pointer;

                  &.confirmed {
                     background-color: #32b8fb;
                     cursor: pointer;

                     &:after {
                        content: "";
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMC4wODQiIGhlaWdodD0iNy43NyIgdmlld0JveD0iMCAwIDEwLjA4NCA3Ljc3Ij48cGF0aCBkPSJNMTcuNTA4LDI0LjQyNmEuNjgyLjY4MiwwLDAsMS0uMzg3LS4xMmwtMy41NDYtMi40MzNhLjY4NS42ODUsMCwwLDEsLjc3NS0xLjEzbDMuMDIzLDIuMDc1LDQuNzctNS45MDdhLjY4NS42ODUsMCwxLDEsMS4wNjYuODYxbC01LjE2OCw2LjRBLjY4NC42ODQsMCwwLDEsMTcuNTA4LDI0LjQyNloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMy4yNzggLTE2LjY1NykiIGZpbGw9IiNmZmYiLz48L3N2Zz4=");
                        background-size: 70%;
                        width: 100%;
                        height: 100%;
                        display: block;
                        background-repeat: no-repeat;
                        background-position: center;
                     }
                  }
               }

               p {
                  margin: 0;
                  margin-left: 1rem;
                  padding-left: 1rem;
                  font-size: 1rem;
                  line-height: 1.5rem;
                  color: #484848;
                  border-left: 1px solid #ccc;
               }
            }
         }
         #application-steps {
            position: relative;
            display: flex;
            justify-content: space-around;
            margin: 3rem 0;
            .step-name {
               cursor: pointer;
               border-radius: 50%;
               display: inline-flex;
               align-items: center;
               justify-content: center;
               width: 38px;
               height: 38px;
               font-size: 1.25rem;
               background-color: #e6e6e6;
               color: #fff;

               z-index: 500;

               &.active {
                  background-color: #33b8fb;
               }
               &.success {
                  background-color: #33fb6b;
                  background-color: #33b8fb;
                  // color: #000;
               }
            }

            &::before {
               content: "";
               position: absolute;
               height: 3px;
               width: 100%;
               background-color: #ccc;
               top: 0;
               bottom: 0;
               margin: auto;
            }

            &.step-1 {
               &::before {
                  background: #33b8fb;
                  background: linear-gradient(90deg, #33b8fb 0%, #33b8fb 18%, #cccccc 0%);
               }
            }
            &.step-2 {
               &::before {
                  background: #33b8fb;
                  background: linear-gradient(90deg, #33b8fb 0%, #33b8fb 50%, #cccccc 0%);
               }
            }
            &.step-3 {
               &::before {
                  background: #33b8fb;
                  background: linear-gradient(90deg, #33fb6b 0%, #33fb6b 85%, #cccccc 0%);
                  background: linear-gradient(90deg, #33b8fb 0%, #33b8fb 100%, #cccccc 0%);
               }
            }

            &.step-3 {
               .active {
                  background-color: #33fb6b;
                  background-color: #33b8fb;
                  border: 1px solid #fff;
               }
            }
         }

         #application-form-actions {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;

            @media (max-width: 576px) {
               flex-direction: column;
            }

            button {
               margin: 0 0.5rem;
               max-height: 46px;
               width: fit-content;

               &.not-allow {
                  background-color: #ccc;
               }

               @media (max-width: 576px) {
                  width: 100%;
                  margin-bottom: 1rem;
               }

               &:last-child {
                  margin-right: 0;
               }

               &.invisible {
                  visibility: hidden;
               }

               &#submit-application {
                  .loader {
                     width: 24px;
                     height: 24px;
                     border: 3px solid #fff;
                     border-bottom-color: transparent;
                     border-radius: 50%;
                     display: inline-block;
                     box-sizing: border-box;
                     animation: rotation 1s linear infinite;
                  }

                  @keyframes rotation {
                     0% {
                        transform: rotate(0deg);
                     }
                     100% {
                        transform: rotate(360deg);
                     }
                  }
               }
            }
         }
         #response {
            p {
               padding: 0 5rem;
               text-align: center;
               font-size: 1.25rem;
               line-height: 1.55;
               color: #363636;
               opacity: 0.9;
            }
         }
      }
   }
}
</style>
